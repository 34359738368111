import Swiper from 'swiper/bundle';
import Cookies from 'js-cookie';
import PhotoSwipe from "photoswipe";
import PhotoSwipeLightbox from "photoswipe";
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default';

function setPrices() {

    var bounderiessqmhtml = jQuery('#bounderiessqm').html();
    var bounderiesqtyhtml = jQuery('#bounderiesqty').html();

    if (bounderiessqmhtml) {
        var bounderies = JSON.parse(bounderiessqmhtml);
    }
    if (bounderiessqmhtml) {
        var bounderiesqty = JSON.parse(bounderiesqtyhtml);
    }

    var sqmperbox = jQuery('#sqmperbox').val();
    var price = jQuery('#price').val();
    var sqm = parseFloat(jQuery('#sqm').val());
    var includewastage = false;

    if(jQuery('#wastagebox').is(":checked")){
        var includewastage = true;
        var wastage = sqm * 0.05;
    } else {
        var wastage = 0;
    }

    var sqmfinal = wastage + sqm;
    var sqmprice = jQuery('#sqmcost').val();

    //if price matches price reduction boundary change sqm price down
    if (bounderies) {
        for (var i = 0, l = bounderies.length; i < l; i++) {
            if (sqm >= parseInt(bounderies[i]['sqm_boundary'])) {
                sqmprice = parseFloat(bounderies[i]['ppm2']);
            }
        }
    }

    var postid = jQuery('#the-post-id').val();
    var boxes = sqmfinal / sqmperbox;
    var boxesrounded = Math.ceil(boxes);
    if (bounderiesqty) {
        for (var i = 0, l = bounderiesqty.length; i < l; i++) {
            if ((boxesrounded + 1) > parseInt(bounderiesqty[i]['boxes'])) {
                price = parseFloat(bounderiesqty[i]['price_per_box']);
            }
        }
    }
    var totalprice = price * boxesrounded;
    var totalpriceinc = totalprice * 1.2;
    var totalcovered = boxesrounded * sqmperbox;
    var message = "To cover <span class=\"dark\">" + sqm + "</span>m² you will need to round up to  <span class=\"dark\">" + boxesrounded + "</span> boxes which will cover  <span class=\"dark\">" + totalcovered.toFixed(3) + "m²</span> at  <span class=\"dark exvat\">&pound;" + sqmprice + " per m²</span><span class=\"dark incvat\">&pound;" + (sqmprice * 1.2).toFixed(2) + " per m²</span>.";
    if(includewastage){
        message += "  Allowing 5% for wastage ("+ wastage.toFixed(2) +" m²)";
    }
    jQuery('#product-message').html(message);
    jQuery('#price-total-ex').text("£" + totalprice.toFixed(2));
    jQuery('#price-total-inc').text("£" + totalpriceinc.toFixed(2));

    var newurl = "/basket/?add-to-cart=" + postid + "&quantity=" + boxesrounded;
    jQuery('#buy-link').attr("href", newurl);
    vat_update();
    return message;
}

function updateQty() {


    var qty = parseInt(jQuery('#qty').val());
    var price = jQuery('#price').val();
    var postid = jQuery('#the-post-id').val();


    var totalprice = qty * price;
    var totalpriceinc = totalprice * 1.2;

    jQuery('#price-total-ex').text("£" + totalprice.toFixed(2));
    jQuery('#price-total-inc').text("£" + totalpriceinc.toFixed(2));

    var newurl = "/basket/?add-to-cart=" + postid + "&quantity=" + qty;
    jQuery('#buy-link').attr("href", newurl);

}

function vat_toggle() {
    var vatstatus = Cookies.get("vat");
    console.log(vatstatus);
    if (vatstatus == 'ex') {
        Cookies.set("vat", 'inc');
        jQuery('.incvat').css('display', 'inline-block');
        jQuery('.exvat').css('display', 'none');

    } else if (vatstatus == 'inc') {
        Cookies.set("vat", 'ex');
        jQuery('.incvat').css('display', 'none');
        jQuery('.exvat').css('display', 'inline-block');

    } else {
        Cookies.set("vat", 'ex');
        jQuery('.incvat').css('display', 'none');
        jQuery('.exvat').css('display', 'inline-block');

    }
}

function vat_update(){
    var vatstatus = Cookies.get("vat");
    console.log(vatstatus);
    if (vatstatus == 'ex') {

        jQuery('.incvat').css('display', 'none');
        jQuery('.exvat').css('display', 'inline-block');
    } else if (vatstatus == 'inc') {
        jQuery('.incvat').css('display', 'inline-block');
        jQuery('.exvat').css('display', 'none');
    } else {
        jQuery('.incvat').css('display', 'inline-block');
        jQuery('.exvat').css('display', 'none');
    }
}

jQuery(document).ready(function () {

    var sqmfromcalc = Cookies.get("sqm");
    if(jQuery('#sqm').length){
        if(sqmfromcalc){
            jQuery('#sqm').val(sqmfromcalc);
            setPrices();

        } else {
            jQuery('#sqm').val(0);
            setPrices();

        }
    }


    jQuery('#sqm').on('click', function () {
        //jQuery('#sqm').val('');
    })

    jQuery('#close').on('click', function () {
        jQuery('#mobilemenu').addClass('hidden');
    })

    jQuery('#menu-button').on('click', function () {
        jQuery('#mobilemenu').removeClass('hidden');
    })


    jQuery('#sqm').on('change', function () {
        setPrices();
    })

    jQuery('#sqm').on('input', function () {
        setPrices();
    })

    jQuery('#wastagebox').on('click', function () {
        setPrices();
    })

    jQuery('#qty').on('change', function () {
        updateQty();
    })

    jQuery('#qty').on('input', function () {
        updateQty();
    })

    jQuery('.product-extra-info .tab').hide();
    jQuery('.product-extra-info .first').show();
    jQuery('.titles .tabtitle').on('click', function () {
        var correcttab = jQuery(this).data('related');
        jQuery('.titles .tabtitle').removeClass('active');
        jQuery(this).addClass('active');
        correcttab = "." + correcttab;
        jQuery('.product-extra-info .tab').hide();
        jQuery(correcttab).show();

    });
    jQuery('.accordion h2').on('click', function () {
        jQuery('.accordion .tabmob').removeClass('active');
        jQuery(this).parent().addClass('active');
        var offset=jQuery(this).offset().top;
        console.log(offset);
        jQuery(window).scrollTop(offset -100);


    });

    /*    var galleryThumbs = new Swiper('.gallery-thumbs', {
            spaceBetween: 10,
            slidesPerView: 3,
            loop: true,
            freeMode: true,
            loopedSlides: 3, //looped slides should be the same
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
        });
        var galleryTop = new Swiper('.gallery-top', {
            spaceBetween: 10,
            loop: true,
            loopedSlides: 5, //looped slides should be the same
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },

        });
    */
    var swiper = new Swiper(".mySwiper", {
        pagination: {
            el: ".swiper-pagination",
        },
    });

    jQuery('.cartvat').on('click', function () {

        vat_toggle();

    });

    jQuery('.toggle-vat').on('click', function () {

        vat_toggle();

    });

    /* Mobile Menu / Accordion */
    jQuery('.title').on('click', function () {
        if(jQuery(this).parent().hasClass('active')){
            jQuery(this).parent().removeClass('active');
        } else {
            jQuery(this).parent().addClass('active');
        }


    });

    jQuery('#nav li').first().addClass("active").find('ul').show();
    jQuery('#nav > li > a').click(function () {
        // console.log(jQuery(this).attr('class');

        if (!jQuery(this).attr('class').includes("active")) {
            jQuery('#nav li ul').slideUp();
            jQuery(this).next().slideToggle();
            jQuery('#nav li a').removeClass('active');
            jQuery(this).addClass('active');
        } else {
            jQuery('#nav li ul').slideUp();
            jQuery('#nav li a').removeClass('active');
        }
    });


    /* TOC
part one - Swiper initialization
part two - photoswipe initialization
part three - photoswipe define options
part four - extra code (update swiper index when image close and micro changes)

/* 1 of 4 : SWIPER ################################### */
    var galleryThumbs = new Swiper('.gallery-thumbs', {
        spaceBetween: 10,
        slidesPerView: 3,
        loop: true,
        freeMode: true,
        loopedSlides: 3, //looped slides should be the same
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
    });

    var mySwiper = new Swiper(".swiper", {
        // If swiper loop is true set photoswipe counterEl: false (line 175 her)
        loop: true,
        /* slidesPerView || auto - if you want to set width by css like flickity.js layout - in this case width:80% by CSS */
        slidesPerView: "auto",
        spaceBetween: 10,
        centeredSlides: true,
        slideToClickedSlide: false,
        /*        autoplay: { /!* remove/comment to stop autoplay  *!/
                    delay: 3000,
                    disableOnInteraction: false /!* true by deafult *!/
                },*/
        // If we need pagination

        // Navigation arrows
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        // keyboard control
        keyboard: {
            enabled: true,
        },
        thumbs: {
            swiper: galleryThumbs,
        },
    });


// 2 of 4 : PHOTOSWIPE #######################################
// https://photoswipe.com/documentation/getting-started.html //

    var initPhotoSwipeFromDOM = function (gallerySelector) {
        // parse slide data (url, title, size ...) from DOM elements
        // (children of gallerySelector)
        var parseThumbnailElements = function (el) {
            var thumbElements = el.childNodes,
                numNodes = thumbElements.length,
                items = [],
                figureEl,
                linkEl,
                size,
                item;

            for (var i = 0; i < numNodes; i++) {
                figureEl = thumbElements[i]; // <figure> element

                // include only element nodes
                if (figureEl.nodeType !== 1) {
                    continue;
                }

                linkEl = figureEl.children[0]; // <a> element

                size = linkEl.getAttribute("data-size").split("x");

                // create slide object
                item = {
                    src: linkEl.getAttribute("href"),
                    w: parseInt(size[0], 10),
                    h: parseInt(size[1], 10)
                };

                if (figureEl.children.length > 1) {
                    // <figcaption> content
                    item.title = figureEl.children[1].innerHTML;
                }

                if (linkEl.children.length > 0) {
                    // <img> thumbnail element, retrieving thumbnail url
                    item.msrc = linkEl.children[0].getAttribute("data-small");
                }

                item.el = figureEl; // save link to element for getThumbBoundsFn
                items.push(item);
            }

            return items;
        };

        // find nearest parent element
        var closest = function closest(el, fn) {
            return el && (fn(el) ? el : closest(el.parentNode, fn));
        };

        // triggers when user clicks on thumbnail
        var onThumbnailsClick = function (e) {
            e = e || window.event;
            e.preventDefault ? e.preventDefault() : (e.returnValue = false);

            var eTarget = e.target || e.srcElement;

            // find root element of slide
            var clickedListItem = closest(eTarget, function (el) {
                return el.tagName && el.tagName.toUpperCase() === "LI";
            });

            if (!clickedListItem) {
                return;
            }

            // find index of clicked item by looping through all child nodes
            // alternatively, you may define index via data- attribute
            var clickedGallery = clickedListItem.parentNode,
                childNodes = clickedListItem.parentNode.childNodes,
                numChildNodes = childNodes.length,
                nodeIndex = 0,
                index;

            for (var i = 0; i < numChildNodes; i++) {
                if (childNodes[i].nodeType !== 1) {
                    continue;
                }

                if (childNodes[i] === clickedListItem) {
                    index = nodeIndex;
                    break;
                }
                nodeIndex++;
            }

            if (index >= 0) {
                // open PhotoSwipe if valid index found
                openPhotoSwipe(index, clickedGallery);
            }
            return false;
        };

        // parse picture index and gallery index from URL (#&pid=1&gid=2)
        var photoswipeParseHash = function () {
            var hash = window.location.hash.substring(1),
                params = {};

            if (hash.length < 5) {
                return params;
            }

            var vars = hash.split("&");
            for (var i = 0; i < vars.length; i++) {
                if (!vars[i]) {
                    continue;
                }
                var pair = vars[i].split("=");
                if (pair.length < 2) {
                    continue;
                }
                params[pair[0]] = pair[1];
            }

            if (params.gid) {
                params.gid = parseInt(params.gid, 10);
            }

            return params;
        };

        var openPhotoSwipe = function (
            index,
            galleryElement,
            disableAnimation,
            fromURL
        ) {
            var pswpElement = document.querySelectorAll(".pswp")[0],
                gallery,
                options,
                items;

            items = parseThumbnailElements(galleryElement);

            // #################### 3/4 define photoswipe options (if needed) ####################
            // https://photoswipe.com/documentation/options.html //
            options = {
                /* "showHideOpacity" uncomment this If dimensions of your small thumbnail don't match dimensions of large image */
                //showHideOpacity:true,

                // Buttons/elements
                closeEl: true,
                captionEl: true,
                fullscreenEl: true,
                zoomEl: true,
                shareEl: false,
                counterEl: false,
                arrowEl: true,
                preloaderEl: true,
                // define gallery index (for URL)
                galleryUID: galleryElement.getAttribute("data-pswp-uid"),
                /*    getThumbBoundsFn: function(index) {
                        // See Options -> getThumbBoundsFn section of documentation for more info
                        var thumbnail = items[index].el.getElementsByTagName("img")[0], // find thumbnail
                            pageYScroll =
                                window.pageYOffset || document.documentElement.scrollTop,
                            rect = thumbnail.getBoundingClientRect();

                        return { x: rect.left, y: rect.top + pageYScroll, w: rect.width };
                    }*/
            };

            // PhotoSwipe opened from URL
            if (fromURL) {
                if (options.galleryPIDs) {
                    // parse real index when custom PIDs are used
                    // http://photoswipe.com/documentation/faq.html#custom-pid-in-url
                    for (var j = 0; j < items.length; j++) {
                        if (items[j].pid == index) {
                            options.index = j;
                            break;
                        }
                    }
                } else {
                    // in URL indexes start from 1
                    options.index = parseInt(index, 10) - 1;
                }
            } else {
                options.index = parseInt(index, 10);
            }

            // exit if index not found
            if (isNaN(options.index)) {
                return;
            }

            if (disableAnimation) {
                options.showAnimationDuration = 0;
            }

            // Pass data to PhotoSwipe and initialize it
            gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, options);
            gallery.init();

            /* ########### PART 4 - EXTRA CODE  ########### */
            /* EXTRA CODE (NOT FROM photoswipe CORE) -
            1/2. UPDATE SWIPER POSITION TO THE "CURRENT" ZOOM_IN IMAGE (BETTER UI) */
            // photoswipe event: Gallery unbinds events
            // (triggers before closing animation)
            gallery.listen("unbindEvents", function () {
                // The index of the current photoswipe slide
                let getCurrentIndex = gallery.getCurrentIndex();
                // Update position of the slider
                mySwiper.slideTo(getCurrentIndex, 0, false);
                // 2/2. Start swiper autoplay (on close - if swiper autoplay is true)
                mySwiper.autoplay.start();
            });
            // 2/2. Extra Code (Not from photoswipe) - swiper autoplay stop when image in zoom mode (When lightbox is open) */
            gallery.listen('initialZoomIn', function () {
                if (mySwiper.autoplay.running) {
                    mySwiper.autoplay.stop();
                }
            });
        };

        // loop through all gallery elements and bind events
        var galleryElements = document.querySelectorAll(gallerySelector);

        for (var i = 0, l = galleryElements.length; i < l; i++) {
            galleryElements[i].setAttribute("data-pswp-uid", i + 1);
            galleryElements[i].onclick = onThumbnailsClick;
        }

        // Parse URL and open gallery if it contains #&pid=3&gid=1
        var hashData = photoswipeParseHash();
        if (hashData.pid && hashData.gid) {
            openPhotoSwipe(hashData.pid, galleryElements[hashData.gid - 1], true, true);
        }
    };

// execute above function
    initPhotoSwipeFromDOM(".my-gallery");

    (function ($) {
        $(document).on('facetwp-loaded', function () {
            vat_update()
            if (FWP.loaded) {
                $('html, body').animate({
                    scrollTop: $('.search-grid').offset().top - 50
                }, 100);
            }
        });
    })(jQuery);

    vat_update();
});

